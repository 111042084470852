import React from "react";
import { formatDate, getTimeLabelForPastDateTime } from "../../../../helpers/dates";
import { Link } from "react-router-dom";
import { getNewsDetailsLinkPath, doScrollToTop, resizedImageURL, isLiveNewsTag, getAssetUrl } from "../../../../helpers/general";
import { ShimmerEffect } from "../../../common/ShimmerEffect";
import { shimmerData } from "./liveMatchesCarousel/helper";
import LiveAnimatedRedDot from "../../../common/LiveAnimatedRedDot";
import { doHardReloadArticle, isTrendingNews } from "../../../../constants";
import CGLink from "../../../common/CGLink";

const rightArrow = getAssetUrl("right-arrow-trending-news.png");

const LatestNews = ({ data, isLoading, isError }) => {

    const homeNewsData = isLoading ? shimmerData : data?.data || {};
    const shimmerClassName = homeNewsData?.[0]?.isShimmer ? "bg-gray-300 loading text-opacity-0" : ""

    if (isError) {
        return <div></div>;
    }
    return (
        <div className="bg-white rounded-xl border">
            <h2 className="md:text-lg md:pt-0 text-sm my-4 font-bold text-center capitalize">{isTrendingNews ? '📈 Trending News' : '📜 Latest News'} </h2>
            {isTrendingNews ?
                <div className="px-4">
                    {homeNewsData.map((obj, i) => (
                        <CGLink
                            key={i}
                            doHardRedirect={doHardReloadArticle}
                            to={!obj.isShimmer && getNewsDetailsLinkPath(obj.slug, obj.id, obj.type, obj)} onClick={doScrollToTop}
                        >
                            <div className={`flex gap-2 my-0.5 md:my-0 items-top md:bg-transparent bg-[#F8F8F8] md:rounded-xl ${i === homeNewsData.length - 1 ? "rounded-b-2xl" : ""} ${i === 0 ? "rounded-t-2xl" : ""}`}>
                                <div className={"w-8 md:w-5 my-2 ps-2 md:ps-0"}>
                                    <img src={rightArrow} alt="right-arrow" className="w-[17px] md:w-full" />
                                </div>
                                <div className="w-full my-2 mr-1">
                                    <p
                                        className={`md:text-[14px] leading-4 text-[12px] font-semibold news-title ${shimmerClassName} min-h-8`}
                                        dangerouslySetInnerHTML={{ __html: obj.title }}
                                    />
                                    <p className={`flex items-center md:text-[12px] text-[10px] leading-[14px] mt-2 font-semibold md:mt-3 text-[#6C6C6C] ${shimmerClassName}`}>
                                        {isLiveNewsTag(obj?.isLive) && <LiveAnimatedRedDot className='me-2' />}
                                        {/* {obj?.date ? <>{formatDate(obj?.date, false)} <span className="mx-[5px]">•</span></> : null} */}
                                        {getTimeLabelForPastDateTime(obj.date)}
                                    </p>
                                </div>
                            </div>
                        </CGLink>
                    ))}
                </div>
                :
                <div className="px-4">
                    {homeNewsData.map((obj, i) => (
                        <CGLink
                            key={i}
                            doHardRedirect={doHardReloadArticle}
                            to={!obj.isShimmer && getNewsDetailsLinkPath(obj.slug, obj.id, obj.type, obj)} onClick={doScrollToTop}
                        >
                            <div className={`my-0.5 md:my-0 grid md:grid-cols-8 grid-cols-10 lg:grid-cols-9 items-center md:bg-transparent bg-[#F8F8F8] md:rounded-xl ${i === homeNewsData.length - 1 ? "rounded-b-2xl" : ""} ${i === 0 ? "rounded-t-2xl" : ""}`}>
                                <div className={"md:col-span-1 lg:col-span-4 col-span-2 my-2 ml-4 md:ml-2"}>
                                    <ShimmerEffect src={resizedImageURL(obj.image, "120x120")} alt="newsImage" className={`md:w-[100px] w-[42px] h-[42px] md:h-[60px] rounded-[6px] ${shimmerClassName}`} />
                                </div>
                                <div className=" md:col-span-7 lg:col-span-5 col-span-8 my-2 md:ml-2">
                                    <p
                                        className={`md:text-[14px] leading-4 text-[12px] font-semibold news-title ${shimmerClassName} min-h-8`}
                                        dangerouslySetInnerHTML={{ __html: obj.title }}
                                    />
                                    <p className={`flex items-center md:text-[12px] text-[10px] leading-[14px] mt-2 font-semibold md:mt-4 text-[#6C6C6C] ${shimmerClassName}`}>
                                        {isLiveNewsTag(obj?.isLive) && <LiveAnimatedRedDot className='me-2' />}
                                        {getTimeLabelForPastDateTime(obj.date)}
                                    </p>
                                </div>
                            </div>
                        </CGLink>
                    ))}
                </div>
            }

            <>
                <div className='flex items-end justify-center h-2 w-full bg-gradient-to-t mt-2 from-[#e2e2e2ab] to-transparent'>
                </div>
                <div className='text-[#3A32D1] text-center py-3 md:text-lg text-xs font-semibold'>
                    <Link
                        key='btnSeeMoreNews2'
                        to='/cricket-news'
                        onClick={doScrollToTop}
                    >
                        See More
                    </Link>
                </div>
            </>
        </div>
    );
};

export default LatestNews;